import {Grid,Typography, Box} from "@mui/material"
import React, { useEffect,useState} from "react"
import AlertMessage from "src/components/alert-message"
import DatalakeService from "src/services/datalake-service"
import PropTypes from 'prop-types'
import ParameterTable from "src/components/table/parameter-table"



const GrupoEconomico = ({empresa,loading }) => {
    const datalakeservice = new DatalakeService()
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })
    const [data, setData] = useState([])
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })

    useEffect(() => {
        console.log("Valor de empresa atualizado:", empresa)
        // Só carrega os dados se 'empresa' possuir um valor válido
        if (empresa) {
            loadData()
        } 
    }, [empresa])


    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const loadData = () => {
        const raizCNPJ = empresa?.RaizCNPJ || ""
    
        // Verifica se 'empresaPrimeiraParte' está vazio
        if (!raizCNPJ) {
            openAlert("CNPJ inválido ou não selecionado. Por favor, verifique.", "warning")
            loading(false)
            return // Sai da função caso a empresa seja inválida
        }
    
        loading(true)
        datalakeservice.loadgrupoecon(raizCNPJ)
            .then((response) => {
                loading(false)
    
                const { body } = response
    
                if (body && body.data) {
                    const { data, count, next, previous } = body
                    const list = data.map((obj, index) => ({ ...obj, id: index }))
    
                    setPagination({ count: count, next: next, previous: previous })
                    setData(list)
                } else {
                    openAlert("Dados não encontrados na resposta.", "warning")
                    setPagination({ count: 0, next: null, previous: null })
                    setData([])
                }
            })
            .catch((error) => {
                loading(false)
                console.error("Erro ao buscar dados:", error)
                setPagination({ count: 0, next: null, previous: null })
                setData([])
            })
    }

    const renderTextColumn = (params) =>
        <Box sx={{ whiteSpace: 'pre-line', height: 'auto' }}>
            <Typography variant="p">{params.value}</Typography>
        </Box>
    

    const columns = [
        {
            field: 'id',
            headerName: '#',
            flex: true,
            minWidth: 50, 
            maxWidth: 70, 
        },
        {
            field: 'RazaoSocial',
            headerName: 'Razão Social',
            flex: 1,
            minWidth: 250,
            maxWidth: 500,
            editable: false,
            renderCell: renderTextColumn
        },
        {
            field: 'NomeFantasia',
            headerName: 'Nome Fantasia',
            flex: 1,
            minWidth: 250,
            maxWidth: 300,
            editable: false,
            renderCell: renderTextColumn
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CNPJ',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            editable: false
        },
        {
            field: 'TipoCadastro',
            headerName: 'Tipo Cadastro',
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
            editable: false
        },
        {
            field: 'Setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 150,
            maxWidth: 300,
            editable:  false,
            renderCell: renderTextColumn
        },
        {
            field: 'SubSetor',
            headerName: 'SubSetor',
            flex: 1,
            minWidth: 150,
            maxWidth: 500,
            editable:  false,
            renderCell: renderTextColumn
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            editable:  false,
        }
    ]
    
    return (
        <Grid>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

            {empresa?.RaizCNPJ && (
                <>
                    <Box 
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: 2,
                            p: 2,
                            backgroundColor: "#ffffff",
                            borderRadius: 2,
                            boxShadow: 2,
                            textAlign: "center"
                        }}>
                        <Typography variant="subtitle1"><strong>Grupo Econômico:</strong> {empresa.GrupoEconomico_Nome || "Não informado"}</Typography>
                        <Typography variant="subtitle1"><strong>Raiz CNPJ:</strong> {String(empresa.GrupoEconomico_RaizCNPJ || "Não informado")}</Typography>
                    </Box>
                    

                    <Grid item xs={12}>
                        <ParameterTable
                            columns={columns}
                            data={data}
                            pagination={pagination}
                            disableDeleteColumn={true}
                            autoHeight
                        />
                    </Grid>
                </>
            )}
        </Grid>

    )
}

GrupoEconomico.propTypes = {
    loading: PropTypes.func,
    empresa: PropTypes.object.isRequired,
}

export default GrupoEconomico