export const onlyTextRegex = (str) => {
    const regex = /^[A-Za-z0-9_.-\s]+$/
    return regex.test(str)
}

export const applyRegex = (str) => {
    return str.replace(/[^A-Za-z0-9_.-\s]/g, '')
}

export const onlyNumberRegex = (str) => {
    return str.replace(/\D/g, '')
}

export const reduzirString = (str, size) => {
    if (str.length <= size) {
        return str // Retorna a string original se for menor ou igual a 31 caracteres
    }
    return str.slice(0, size) // Trunca a string para os primeiros 31 caracteres
}

export const getTextValue = (input) => {
    if (input && input.text) {
        return input.text
    }

    return null
}
