import { Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import DatePickerSelect from "src/components/date/date-picker-select"
import LayoutPage from "src/components/page-layout/layout-page"
import PriceService from "src/services/price-service"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import PropTypes from "prop-types"

const DATE_FORMAT_API = "YYYY-MM-DD"
const DATE_FORMAT_DISPLAY = "DD/MM/YYYY"

const getPreviousBusinessDay = () => {
    let date = dayjs().subtract(1, "day")
    
    while (date.day() === 0 || date.day() === 6) {
        date = date.subtract(1, "day")
    }
    
    return date.format(DATE_FORMAT_API)
}

const CustomersPriceLogPage = ({ IDCliente }) => {
    const priceService = new PriceService()

    const [loading, setLoading] = useState(false)
    const [priceList, setPriceList] = useState([])
    const [clienteId, setClienteId] = useState(null)
    const [selectedDate, setSelectedDate] = useState(getPreviousBusinessDay())

    useEffect(() => {
        const jwt = localStorage.getItem("jwt")
        if (jwt) {
            document.cookie = `c2p_jwt=${jwt} domain=.c2ps.com.br path=/`
            try {
                const payload = JSON.parse(atob(jwt.split(".")[1]))
                if (payload.user?.IDCliente) {
                    setClienteId(payload.user.IDCliente)
                }
            } catch (error) {
                console.error("Erro ao decodificar o token JWT:", error)
            }
        }
    }, [])

    useEffect(() => {
        if (clienteId || IDCliente) {
            loadPrices(clienteId || IDCliente, selectedDate)
        }
    }, [clienteId, IDCliente, selectedDate])

    const loadPrices = async (IDCliente, date) => {
        try {
            setLoading(true)
            const response = await priceService.getCustumersPricesLog(IDCliente, date)
            const { data } = response.body || {}

            if (Array.isArray(data)) {
                const filteredData = data.filter((obj) => {
                    return dayjs(obj.DataReferencia).format(DATE_FORMAT_API) === date
                })

                setPriceList(filteredData.map((obj, index) => ({ ...obj, id: index })))
            } else {
                setPriceList([])
            }
        } catch (error) {
            console.error("Erro ao buscar os logs de preços de clientes:", error)
            setPriceList([])
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            field: "DataReferencia",
            headerName: "Data Referência",
            minWidth: 150,
            type: "date",
            valueFormatter: (params) =>
                params.value ? dayjs(params.value).format(DATE_FORMAT_DISPLAY) : "",
        },
        {
            field: "CETIP",
            headerName: "CETIP",
            flex: true,
            minWidth: 250,
            renderCell: (params) => <div title={params.value}>{params.value}</div>,
        },
        {
            field: "Inclusao",
            headerName: "Inclusão",
            flex: 1,
            minWidth: 50,
            valueFormatter: (params) => (params.value ? "Sim" : "Não"),
        },
        {
            field: "Exclusao",
            headerName: "Exclusão",
            flex: 1,
            minWidth: 50,
            valueFormatter: (params) => (params.value ? "Sim" : "Não"),
        },
        {
            field: "Observacao",
            headerName: "Observação",
            flex: true,
            minWidth: 500,
            renderCell: (params) => <div title={params.value}>{params.value}</div>,
        },
    ]

    return (
        <LayoutPage loading={loading} title="Preços Log">
            <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Grid item>
                    <DatePickerSelect
                        value={selectedDate}
                        label="Data de Exibição"
                        onChange={(date) => setSelectedDate(dayjs(date).format(DATE_FORMAT_API))}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xl={12} md={12} xs={12}>
                    <Paper>
                        <DataGrid
                            autoHeight
                            rows={priceList}
                            columns={columns}
                            pageSize={25}
                            disableRowSelectionOnClick
                            sortingOrder={["asc", "desc"]}
                            localeText={localeBRDataGridText}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </LayoutPage>
    )
}

CustomersPriceLogPage.propTypes = {
    IDCliente: PropTypes.string.isRequired,
}

export default CustomersPriceLogPage
