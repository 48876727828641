import { Autocomplete, Button, CircularProgress, DialogActions, Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import EmissaoService from "src/services/emissao-service"
import PropTypes from 'prop-types'
import DoubleInput from "src/components/double-inputs"
import { formatInputDate } from "src/utils/format-date"
import TextFieldFormatCurrency from "src/components/text-field-format-currency"
import Loading from "src/components/loading"

const inputs = [
    { label: "CETIP", value: "CETIP", required: true },
    { label: "Versão Documento", value: "VersaoDocumento", required: true },
    { label: "Data Documentação", value: "DataDocumentacao", required: true, type: "date" },
    { label: "Cota Subordinação", value: "CotaSubordinacao", required: false },
    { label: "Série", value: "Serie", required: false },
    { label: "Emissão", value: "Emissao", required: false },
    { label: "Tipo Amortização", value: "TipoAmortizacao", required: false },
    { label: "Valor Série", value: "ValorSerie", required: false, type: "number" },
    { label: "Valor Total Emissão", value: "ValorTotalEmissao", required: false, type: "number" },
    { label: "Pre Pagamento", value: "PrePagamento", required: false },
    { label: "Repactuação Crédito", value: "RepactuacaoCredito", required: false },
    { label: "Conta Vinculada", value: "ContaVinculada", required: false },
]

const inputsTextArea = [
    { label: "Resumo Devedor", value: "ResumoDevedor", required: false },
    { label: "Tipo de Lastro", value: "TipoLastro", required: false },
    { label: "Coobrigação", value: "Coobrigacao", required: false },
    { label: "Covenants", value: "Covenants", required: false },
]

const inputsDoubleLine = [
    { label: "Devedor", value: "Devedor", required: false },
    { label: "CNPJ/CPF Devedor", value: "CNPJ_CPF_Devedor", required: false },
    { label: "Securitizador", value: "Securitizador", required: false },
    { label: "Agência de Rating", value: "AgenciaRating", required: false },
    { label: "Agente Fiduciário", value: "AgenteFiduciario", required: false },
    { label: "Auditoria", value: "Auditoria", required: false },
    { label: "Cedente", value: "Cedente", required: false },
    { label: "Coordenador Líder", value: "CoordenadorLider", required: false },
    { label: "Custodiante", value: "Custodiante", required: false },
    { label: "IF Conta Recebedora", value: "IFContaRecebedora", required: false },
]

const ltvInputs = [
    { label: "Índice de Subordinação", value: "IndiceSubordinacao", required: false, type: "number" },
    { label: "LTV", value: "LTV", required: true, type: "number" },
]

const doubleInputs = [
    { label: "Alienação Fiduciária", value: "AlienacaoFiduciaria", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Cessão Fiduciária", value: "CessaoFiduciaria", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Hipoteca", value: "Hipoteca", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Penhor", value: "Penhor", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Avalista", value: "Avalista", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fiança", value: "Fianca", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fundo de Obra", value: "FundoObra", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fundo Reserva", value: "FundoReserva", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fundo de Liquidez", value: "FundoLiquidez", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Seguro", value: "Seguro", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Sobregarantia", value: "Sobregarantia", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fiança Bancária", value: "FiancaBancaria", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
]

const RegisterCetip = ({ afterRegister }) => {
    const emissaoService = new EmissaoService()
    const [valueBody, setValueBody] = useState({})
    const [loading, setLoading] = useState(false)
    const [fieldData, setFieldData] = useState(
        inputsDoubleLine.reduce((acc, input) => {
            acc[input.value] = {
                loading: false,
                options: [],
            }
            return acc
        }, {})
    )


    const loadContraParteInfo = async (inputForm, input, value) => {
        setFieldData((prev) => ({
            ...prev,
            [inputForm]: { ...prev[inputForm], loading: true },
        }))


        emissaoService.recuperaDadosContraparte(input, value)
            .then((response) => {
                const { body } = response
                const { results } = body
                const inputResults = results.map(item => item[input])

                setFieldData((prev) => ({
                    ...prev,
                    [inputForm]: { ...prev[inputForm], options: inputResults, loading: false },
                }))
            })
            .catch((error) => {
                console.error("Error:", error)
            })
            .finally(() => {
                setFieldData((prev) => ({
                    ...prev,
                    [inputForm]: { ...prev[inputForm], loading: false },
                }))
            })
    }

    const onChangeInputLoadContraParte = (event) => {
        const { target } = event
        const inputValue = target.value
        const inputName = target.name

        if (inputName === "CNPJ_CPF_Devedor" && inputValue.length > 1) {
            loadContraParteInfo(inputName, "CPFCNPJ", inputValue)
        } else {
            loadContraParteInfo(inputName, "RazaoSocial", inputValue)
        }

        onChangeInput(event)
    }

    const onChangeInput = (event) => {
        const { target } = event

        let inputValue = target.value
        const inputName = target.name
        const idInput = target.id

        if (idInput === "date") {
            inputValue = formatInputDate(inputValue)
        }

        calculaLTVSetBody(inputName, inputValue)
    }

    const onChangeDoubleInput = (valueInput) => {
        const { key, values } = valueInput
        calculaLTVSetBody(key, values)
    }

    const calculaLTVSetBody = (key, value) => {
        let body = { ...valueBody, ...{ [key]: value, } }
        let ltvValue = ""

        if (key !== "LTV") {
            ltvValue = emissaoService.calculaLTV(body)
            body = { ...valueBody, ...{ [key]: value, "LTV": ltvValue } }
        }

        if (key === "VersaoDocumento" || key === "CETIP") {
            verificaVersaoDocumentoCetip(body)
        }

        if (key === "DataDocumentacao" || key === "CETIP") {
            verificaDataReferenciaCetip(body)
        }

        setValueBody(body)
    }

    const verificaVersaoDocumentoCetip = (body) => {
        const { CETIP, VersaoDocumento } = body

        // Verifica se os campos estão preenchidos e o CETIP possui mais de 4 caracteres
        if (CETIP && VersaoDocumento && CETIP.length > 4) {
            emissaoService.verificaExisteCetipVersaoDocumento(CETIP, VersaoDocumento)
                .then((response) => {
                    const { body: { results } } = response

                    if (results.length > 0) {
                        afterRegister("Versão do Documento para esse CETIP já existe", "error")
                    }
                })
                .catch(() => {
                    console.error("Erro ao verificar a versão do documento para o CETIP.")
                })
        }
    }

    const verificaDataReferenciaCetip = (body) => {
        const { CETIP, DataDocumentacao } = body

        // Verifica se os campos estão preenchidos e atendem os requisitos de tamanho
        if (CETIP && DataDocumentacao && CETIP.length > 4 && DataDocumentacao.length > 9) {
            emissaoService.verificaExisteCetipDataReferencia(CETIP, DataDocumentacao)
                .then((response) => {
                    const { body: { results } } = response

                    if (results.length > 0) {
                        afterRegister("Data Documentação para esse CETIP já existe", "error")
                    }
                })
                .catch(() => {
                    console.error("Erro ao verificar a data de referência para o CETIP.")
                })
        }
    }

    const registerCaracteristicas = async () => {
        setLoading(true)
        try {
            // Registra as características da emissão
            const { body: { ID } } = await emissaoService.registerCaracteristicasEmissao(valueBody)
            registerLTVEmissao(ID)
        } catch (err) {
            handleError(err, "Erro ao registrar características da emissão.")
            setLoading(false)
        }
    }

    const registerLTVEmissao = async (idCaracteristicas) => {
        try {
            setLoading(true)
            await emissaoService.registerLTVEmissao(valueBody)
            afterRegister("Novo Cetip Cadastrado", "success")
            setLoading(false)
        } catch (err) {
            await removeCaracteristicas(idCaracteristicas)
            handleError(err, "Erro ao registrar LTV da emissão.")
        }
    }

    const removeCaracteristicas = async (idCaracteristicas) => {
        try {
            setLoading(true)
            await emissaoService.removeCaracteristicas(idCaracteristicas)
        } catch (err) {
            console.error("Erro ao remover características:", err)
        } finally {
            setLoading(false)
        }
    }

    const handleError = (err, defaultMessage) => {
        try {
            const { response: { body: { error } = {}, text } = {} } = err
            // Verifica se existe uma mensagem de erro no campo `body.error` ou `text`
            const errorMessage = error || text || defaultMessage
            afterRegister(errorMessage, "error")
        } catch (parseError) {
            // Fallback para o caso de erro inesperado no formato do erro
            afterRegister(defaultMessage, "error")
        }
    }

    return (
        <>
            <Loading show={loading} />
            {/* inputs */}
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputs.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            {input.type !== "number" ?
                                <TextField
                                    fullWidth
                                    label={input.label}
                                    value={valueBody[input.value]}
                                    name={input.value}
                                    id={input.type ? input.type : "text"}
                                    required={input.required}
                                    inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                    onChange={onChangeInput}
                                />
                                :
                                <TextFieldFormatCurrency
                                    label={input.label}
                                    variant="outlined"
                                    key={input.label}
                                    value={valueBody[input.value]}
                                    onChange={(value) => onChangeInput({
                                        target: {
                                            name: input.value,
                                            id: "number",
                                            value: value,
                                        },
                                    })}
                                />
                            }
                        </Grid>
                    ))
                }
            </Grid>

            {/* double line */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputsDoubleLine.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            <Autocomplete
                                inputValue={valueBody[input.value]}
                                freeSolo
                                disablePortal
                                loading={fieldData[input.value].loading || false}
                                options={fieldData[input.value].options || []}
                                name={input.value}
                                onInputChange={(event, newValue) => {
                                    const simulatedEvent = {
                                        target: {
                                            id: input.type || "text", // Verifica se `input` existe antes de acessar `type`
                                            name: input.value || "defaultName", // Garante um valor padrão para o `name`
                                            value: newValue || "", // Garante que `newValue` tenha um valor padrão
                                        },
                                    }
                                    onChangeInput(simulatedEvent)
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        multiline
                                        id={input.type ? input.type : "text"}
                                        rows={2}
                                        name={input.value}
                                        label={input.label}
                                        required={input.required}
                                        onChange={onChangeInputLoadContraParte}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {fieldData[input.value].loading ? (
                                                        <CircularProgress color="primary" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} >
                                        {option}
                                    </li>
                                )}
                                noOptionsText={
                                    fieldData[input.value].loading
                                        ? "Carregando..."
                                        : "Nenhum resultado encontrado"
                                }
                                loadingText="Carregando opções..."
                            />
                        </Grid>
                    ))
                }
            </Grid>

            {/* text area */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputsTextArea.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                value={valueBody[input.value]}
                                label={input.label}
                                name={input.value}
                                required={input.required}
                                inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                onChange={onChangeInput} />
                        </Grid>

                    ))
                }
            </Grid>

            {/* ltvInputs */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    ltvInputs.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            {input.type !== "number" ?
                                <TextField
                                    fullWidth
                                    focused={valueBody[input.value] !== ""}
                                    label={input.label}
                                    value={valueBody[input.value]}
                                    name={input.value}
                                    id={input.type ? input.type : "text"}
                                    required={input.required}
                                    inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                    onChange={onChangeInput} />
                                :
                                <TextFieldFormatCurrency
                                    label={input.label}
                                    focused={!!valueBody[input.value]}
                                    variant="outlined"
                                    key={input.label}
                                    value={valueBody[input.value]}
                                    onChange={(value) => onChangeInput({
                                        target: {
                                            name: input.value,
                                            id: "number",
                                            value: value,
                                        },
                                    })}
                                />
                            }
                        </Grid>
                    ))
                }
            </Grid>

            {/* doubleInputs */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    doubleInputs.map((input) => (
                        <Grid item xs={12} md={6} xl={6} key={input.label}>
                            <DoubleInput input={input} multiline={true} multilineRow={4} onChange={onChangeDoubleInput} />
                        </Grid>
                    ))
                }
            </Grid>

            <br />
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={registerCaracteristicas}>Cadastrar</Button>
            </DialogActions>
        </>
    )
}

RegisterCetip.propTypes = {
    afterRegister: PropTypes.func
}

export default RegisterCetip