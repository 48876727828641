import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material"
import PriceCharacteristicsEmissao from "../../prices/characteristcs/table-characteristics/price-characteristics-emissao"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PriceCharacteristicsGuarantee from "../../prices/characteristcs/table-characteristics/price-characteristics-guarantee"
import UltimoRatingEmissao from "./accordion/ultimo-rating-emissao"
import UltimoRatingDevedor from "./accordion/ultimo-rating-devedor"
import UltimoEventoCreditoEmissao from "./accordion/ultimo-evento-credito-emissao"
import UltimoEventoCreditoDevedor from "./accordion/ultimo-evento-credito-devedor"
import UltimoNegocio from "./accordion/ultimo-negocio"
import CharacteristicsService from "src/services/characteristics-service"
import PropTypes from 'prop-types'
import SkeletonTable from "src/components/skeleton-table"
import Loading from "src/components/loading"
import ModalDialogPage from "src/components/modal-dialog"
import TableFincsMarketData from "./table/table_fincs_marketdata"
import TableEmissaoCaracteristicas from "./table/table_emissao_caracteristicas"
import TableEmissaoLTV from "./table/table_emissao_ltv"
import FabButton from "src/components/fab-button"
import RegisterCetip from "../register/register-cetip"
import AlertMessage from "src/components/alert-message"
import AddRoundedIcon from '@mui/icons-material/AddRounded'

const EmissaoTab = ({ cetipIsin, value, admin }) => {
    const [emissaoData, setEmissaoData] = useState([])
    const [garantiasData, setGarantiasData] = useState([])
    const caracteristicasService = new CharacteristicsService()
    const [cpfCnpj, setCpfCnpj] = useState("")
    const [expanded, setExpanded] = useState(false)
    const [openRegisterCetip, setOpenRegisterCetip] = useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [dadosEmpresa, setDadosEmpresa] = useState({
        vencimento: "",
        dataResgateAntecipado: "",
        cpfCnpj: "",
        devedor: "",
        grupoEconomico: "",
    })
    const [loading, setLoading] = useState(false)
    const [loadTab, setLoadTab] = useState(false)
    const [showGarantias, setShowGarantias] = useState(false)
    const [ultimoEventoCreditoDevedorKey, setUltimoEventoCreditoDevedorKey] = useState(Math.random())
    const [ultimoRatingDevedorKey, setUltimoRatingDevedorKey] = useState(Math.random())
    const [openTableModal, setOpenTableModal] = useState({
        open: false,
        title: "",
        component: <></>,
    })

    useEffect(() => {
        if (value && value.length > 4) { loadEmissaoData() }
    }, [value])

    const loadEmissaoData = () => {
        setLoading(true)
        const promisses = [
            caracteristicasService.getCharacteristics(value),
            caracteristicasService.getGuaranteeCalc(value)
        ]

        const clearData = () => {
            setGarantiasData([])
            setEmissaoData([])
        }

        Promise.all(promisses)
            .then((response) => {
                const [emissaoCaracteristicas, garantias] = response
                const isEmissaoDataValid = typeof emissaoCaracteristicas.body !== 'string' && emissaoCaracteristicas.body[0]
                const isGarantiasDataValid = typeof garantias.body !== 'string' && garantias.body[0]

                if (isEmissaoDataValid) {
                    if (!expanded) {
                        toggleAccordion()
                    }
                    const { CPF_CNPJ_Devedor, Devedor, Setor, SubSetor, DataResgateAntecipado, DataVencimento, GrupoEconomico } = emissaoCaracteristicas.body[0]
                    setCpfCnpj(CPF_CNPJ_Devedor)
                    setDadosEmpresa({
                        setor: Setor || "",
                        subsetor: SubSetor || "",
                        devedor: Devedor || "",
                        cpfCnpj: CPF_CNPJ_Devedor || "",
                        dataResgateAntecipado: DataResgateAntecipado || "",
                        vencimento: DataVencimento || "",
                        grupoEconomico: GrupoEconomico || ""
                    })
                    setUltimoEventoCreditoDevedorKey(Math.random())
                    setUltimoRatingDevedorKey(Math.random())
                    setEmissaoData(emissaoCaracteristicas.body[0])
                } else {
                    clearData()
                }

                if (isGarantiasDataValid) {
                    setGarantiasData(garantias.body[0])
                    setShowGarantias(true)
                } else {
                    setShowGarantias(false)
                }
            })
            .catch(() => {
                clearData()
            })
            .finally(() => { setLoading(false) })
    }

    const toggleAccordion = () => {
        setExpanded(!expanded)
    }

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenRegisterCetip(true)
        },
        color: "primary",
    }

    const afterRegister = (message, status) => {
        if (status === "error") {
            openAlert(message, status)
            return
        }

        openAlert(message, status)
        setOpenRegisterCetip(false)
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    return <>
        <Loading show={loadTab} />
        <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
        <Grid
            container
            direction="row"
            alignContent="flex-start"
            justifyContent="flex-start"
            spacing={0}
            sx={{
                padding: 2,
                height: "100%", // altura de 100% da Box
            }}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <Accordion expanded={expanded} onChange={(e, isExpanded) => setExpanded(isExpanded)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Características e Garantias</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading ? <SkeletonTable /> : <> <PriceCharacteristicsEmissao data={emissaoData} /> <br /></>}
                        {loading ? <SkeletonTable /> : (showGarantias ? <> <PriceCharacteristicsGuarantee data={garantiasData} /> <br /></> : null)}
                        <br />
                        {
                            admin ?
                                <Grid container direction="row" alignItems={"center"} justifyContent="flex-start" spacing={2}>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            if (cetipIsin.cetip === '' || cetipIsin.isin === '') return
                                            setOpenTableModal({
                                                open: true,
                                                title: "FINCS MarketData",
                                                component: <TableFincsMarketData loading={setLoadTab} cetipIsin={cetipIsin} />
                                            })
                                        }}>FINCS MarketData</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            if (cetipIsin.cetip === '' || cetipIsin.isin === '') return
                                            setOpenTableModal({
                                                open: true,
                                                title: "Emissão Característica",
                                                component: <TableEmissaoCaracteristicas loading={setLoadTab} cetipIsin={cetipIsin} />
                                            })
                                        }}>Emissão Característica</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => {
                                            if (cetipIsin.cetip === '' || cetipIsin.isin === '') return
                                            setOpenTableModal({
                                                open: true,
                                                title: "Emissão LTV",
                                                component: <TableEmissaoLTV loading={setLoadTab} cetipIsin={cetipIsin} />
                                            })
                                        }}>Emissão LTV</Button>
                                    </Grid>
                                </Grid>
                                : <></>
                        }
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ultimo-rating-emissao-content"
                        id="ultimo-rating-emissao-content"
                    >
                        <Typography>Último Rating Emissão</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UltimoRatingEmissao admin={admin} cetipIsin={cetipIsin} value={value} dadosEmpresa={dadosEmpresa} loading={setLoadTab} />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ultimo-rating-devedor-content"
                        id="ultimo-rating-devedor-content"
                    >
                        <Typography>Último Rating Devedor</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UltimoRatingDevedor admin={admin} cpfCnpj={cpfCnpj} key={ultimoRatingDevedorKey} dadosEmpresa={dadosEmpresa} loading={setLoadTab} />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ultimo-evento-credito-emissao-content"
                        id="ultimo-evento-credito-emissao-content"
                    >
                        <Typography>Último Evento de Crédito Emissão</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UltimoEventoCreditoEmissao admin={admin} cetipIsin={cetipIsin} value={value} loading={setLoadTab} />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ultimo-evento-credito-devedor-content"
                        id="ultimo-evento-credito-devedor-content"
                    >
                        <Typography>Último Evento de Crédito Devedor</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UltimoEventoCreditoDevedor admin={admin} cpfCnpj={cpfCnpj} key={ultimoEventoCreditoDevedorKey} loading={setLoadTab} dadosEmpresa={dadosEmpresa} value={value} />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ultimos-negocios-content"
                        id="ultimos-negocios-content"
                    >
                        <Typography>Últimos Negócios</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UltimoNegocio value={value} cetipIsin={cetipIsin} loading={setLoadTab} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>

        <ModalDialogPage open={openTableModal.open}
            title={openTableModal.title}
            close={() => {
                setOpenTableModal({
                    open: false,
                    component: null,
                    title: null,
                })
            }}>
            <>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        {openTableModal.component}
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>

        {
            admin ? <ModalDialogPage
                open={openRegisterCetip}
                title={"Cadastrar Características e Garantias da Emissão"}
                close={() => {
                    setOpenRegisterCetip(false)
                }}
                button={<FabButton action={actionButton} />}>
                <RegisterCetip afterRegister={afterRegister} />
            </ModalDialogPage> : null
        }

    </>
}

EmissaoTab.propTypes = {
    admin: PropTypes.bool,
    cetipIsin: PropTypes.object,
    value: PropTypes.string,
}

export default EmissaoTab