import { Button, Chip, Divider, Grid, Link, Paper, TextField, Tooltip, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import ModalDialogPage from "src/components/modal-dialog"
import FabButton from "src/components/fab-button"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { DataGrid } from "@mui/x-data-grid"
import ReportButtons from "./components/reportButtons"
import { useDropzone } from "react-dropzone"
import ReceivableService from "src/services/receivable-service"
import ImportFileService from "src/services/import-file-service"
import AlertMessage from "src/components/alert-message"
import { formatTimestamp } from "src/utils/format-date"
import ProgressBar from "src/components/progress-bar"
import TableFormatterService from "./components/tableFormatter"
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { applyRegex, onlyTextRegex } from "src/utils/strings"
import EditableInputComponent from "src/components/editable-input-component"
import OnlineStatus from "src/components/online-status"
import BoaVistaService from "src/services/boa_vista_service"

import { EXAMPLE_CONCESSION_FILE, API_PATH } from "src/utils/api-routes"


const ConcessionsPage = () => {
    const receivableService = new ReceivableService()
    const boaVistaService = new BoaVistaService()
    const importFileService = new ImportFileService()
    const tableFormatter = new TableFormatterService()
    const [openAddOperation, setOpenAddOperation] = useState(false)
    const [rowState, setRowState] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [filename, setFilename] = useState(null)
    const [filenameError, setFilenameError] = useState(false)
    const [boaVistaStatus, setBoaVistaStatus] = useState({
        pf: {
            status_api: false,
            status_code: 0
        },
        pj: {
            status_api: false,
            status_code: 0
        }
    })
    const [boaVistaLoading, setBoaVistaLoading] = useState(true)

    useEffect(() => {
        loadOperations()
        loadBoaVistaStatus()
    }, [])

    const formatApiResponse = (response) => {
        const { body } = response
        const rows = body.response.map((row, index) => {
            const { Concessao, Arquivo } = row

            return {
                id: index,
                concessaoId: Concessao.UUID,
                stockName: Concessao.NomeConcessao,
                date: Concessao.CriadoEm,
                user: Concessao.Usuario.Nome,
                arquivo: Arquivo,
                progress: tableFormatter.progressBarConcession(Arquivo, Concessao),
                status: getCurrentStatus(Concessao.Status, Arquivo.Status),
                reports: {
                    csv: Concessao.DownloadArquivoEndpoint,
                    pdf: Concessao.DownloadRelatorioEndpoint
                },
            }
        })

        return rows
    }

    const getCurrentStatus = (ConcessaoStatus, ArquivoStatus) => {

        console.log('status', ArquivoStatus, ConcessaoStatus)
        if (ArquivoStatus !== "DONE") {
            return tableFormatter.verifyTableStatus(ArquivoStatus, "Arquivo")
        }

        return tableFormatter.verifyTableStatus(ConcessaoStatus, "Concessao")

    }

    const reloadOperations = () => {
        setRowState([])
        loadOperations()
        loadBoaVistaStatus()
    }

    const loadBoaVistaStatus = () => {
        setBoaVistaLoading(true)
        boaVistaService.getApiStatus().then((response) => {
            const { body } = response
            const { pf, pj } = body

            setBoaVistaStatus({
                pf: pf,
                pj: pj
            })
            setBoaVistaLoading(false)
        }).catch(() => {
            setBoaVistaLoading(false)
        })
    }

    const loadOperations = () => {
        setLoading(true)
        receivableService.getConcessions(5000, 0).then((response) => {
            const { body } = response
            setRowState(formatApiResponse(response))
            setRowCount(body.count)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setFilename(null)
            setFilenameError(false)
            setOpenAddOperation(true)
            setSelectedFile(null)
        },
        color: "primary",
    }

    const verifyTableStatus = (params) => {
        const { text, color } = params.value
        const { row } = params
        const { progress } = row

        return <Tooltip title={`processados ${progress.processed} de ${progress.registers}`}>
            <Chip label={text} variant="contained" color={color} />
        </Tooltip>
    }

    const progressBar = (params) => {
        const { row } = params
        const { progress } = row

        return <Tooltip>
            <ProgressBar value={Number(progress.progress)} />
        </Tooltip>
    }

    const updateNomeConcessao = (params, value) => {
        if (!onlyTextRegex(value)) {
            openAlert("Texto Digitado Inválido", "warning")
            return
        }

        const { concessaoId } = params
        setLoading(true)
        receivableService.updateConcessionName(concessaoId, value)
            .then((response) => {
                setLoading(false)
                openAlert("Nome Concessão Alterado", "success")
                console.log(response)
            })
            .catch((err) => {
                setLoading(false)
                openAlert("Não foi possível alterar o Nome Concessão Alterado", "error")
                console.error(err)
            })
    }

    const columns = [
        {
            field: 'stockName',
            headerName: 'Nome Concessão',
            renderCell: ({ row, value }) => <EditableInputComponent
                editable={true}
                fieldValue={value}
                handleChange={(cellValue) => { updateNomeConcessao(row, cellValue) }} />,
            maxWidth: 650,
            flex: true,
        },
        {
            field: 'date',
            headerName: 'Data',
            flex: true,
            minWidth: 200,
            maxWidth: 200,
            renderCell: (params) => tableFormatter.formatDate(params.value)
        },
        {
            field: 'user',
            headerName: 'Usuário',
            flex: true,
            minWidth: 200,
            maxWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: true,
            minWidth: 250,
            maxWidth: 250,
            renderCell: verifyTableStatus
        },
        {
            field: 'Arquivo',
            headerName: 'Progresso',
            flex: true,
            minWidth: 150,
            maxWidth: 150,
            renderCell: progressBar
        },
        {
            field: 'reports',
            headerName: 'Relatórios',
            flex: true,
            renderCell: (params) => <ReportButtons openAlert={openAlert} disabled={params.row.status.rawStatus != "DONE"} item={params.row} reportLinks={params.value} loading={setLoading} />,
            minWidth: 200,
            maxWidth: 200,
        }
    ]

    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })


    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }
    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        setFilename(applyRegex(file.name).slice(0, -4))
        setSelectedFile(file)
    }

    const handleUpload = () => {
        if (!onlyTextRegex(filename)) {
            setFilenameError(true)
            return
        }

        if (selectedFile) {
            setLoading(true)
            importFileService.sendReceivableOperationFile(selectedFile, filename)
                .then(() => {
                    setLoading(false)
                    openAlert("Arquivo enviado com sucesso", "success")
                })
                .catch((error) => {
                    console.debug(error)
                    setLoading(false)
                    openAlert("Houve um erro ao enviar o arquivo, tente novamente.", "error")
                })
                .finally(() => {
                    setOpenAddOperation(false)
                })
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    const changeFilename = (event) => {
        const value = event.target.value

        if (onlyTextRegex(value)) {
            setFilenameError(false)
            return setFilename(value)
        }

        setFilenameError(true)
    }


    return (
        <LayoutPage
            title="Histórico de concessões" loading={loading}>
            <>
                <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
                <div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item sx={{ marginBottom: 1 }}>
                            <Typography variant="h4">Histórico</Typography>
                            <Typography level="title-md">Chamadas de Concessões</Typography>
                        </Grid>

                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={3}
                            >
                                <Grid item>
                                    <Grid container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={1}>
                                        <Grid item>
                                            <OnlineStatus
                                                label={"Boa Vista PF"}
                                                title={`Status retornado: ${boaVistaStatus.pf.status_code}`}
                                                loading={boaVistaLoading}
                                                color={boaVistaStatus.pf.status_code === 401 ? "warning" : null}
                                                status={boaVistaStatus.pf.status_api} />
                                        </Grid>
                                        <Grid item>
                                            <OnlineStatus
                                                label={"Boa Vista PJ"}
                                                loading={boaVistaLoading}
                                                title={`Status retornado: ${boaVistaStatus.pj.status_code}`}
                                                color={boaVistaStatus.pj.status_code === 401 ? "warning" : null}
                                                status={boaVistaStatus.pj.status_api} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={() => reloadOperations()}><RefreshRoundedIcon /></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="center" spacing={2}>
                        <Grid item xl={12} md={12} xs={12}>
                            <Paper>
                                <DataGrid
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 25, page: 0 },
                                        },
                                    }}
                                    autoHeight
                                    isableRowSelectionOnClick
                                    getRowHeight={() => 45}
                                    rows={rowState}
                                    columns={columns}
                                    rowCount={rowCount}
                                    disableRowSelectionOnClick
                                    sortingOrder={['asc', 'desc']}
                                    localeText={localeBRDataGridText}
                                />
                            </Paper>
                        </Grid>
                    </Grid>

                    <ModalDialogPage
                        open={openAddOperation}
                        title="Adicionar Chamada"
                        close={() => {
                            setOpenAddOperation(false)
                        }}
                        button={<FabButton action={actionButton} />}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                            minHeight={250}>
                            <Grid item>
                                Modelo de arquivo para upload: <Link href={`${API_PATH}${EXAMPLE_CONCESSION_FILE}`}>Exemplo</Link>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6} xl={6} >
                                        <div {...getRootProps({ className: 'dropzone' })} >
                                            <input {...getInputProps()} />
                                            <Button variant="outlined">
                                                Arraste e solte o arquivo aqui ou clique para selecionar
                                            </Button>
                                        </div>
                                    </Grid>
                                    {selectedFile ?
                                        <Grid item xs={12} md={6} xl={6}>
                                            <Typography variant="subtitle" gutterBottom>Nome: <b>{selectedFile.name.substring(0, 40)}</b></Typography>
                                            <Divider />
                                            <Typography variant="subtitle" gutterBottom>Data de Modificação: <b>{formatTimestamp(selectedFile.lastModified)}</b></Typography>
                                            <Divider />
                                            <Typography variant="subtitle" gutterBottom>Tamanho do Arquivo (MB): <b>{tableFormatter.getFileSize(selectedFile.size)}</b></Typography>
                                            <Divider />
                                            <Typography variant="subtitle" gutterBottom>Tipo: <b>{selectedFile.type}</b></Typography>
                                            <Divider />
                                        </Grid>
                                        : null}
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                <TextField
                                    sx={{ flexGrow: '1' }}
                                    variant="standard"
                                    value={filename}
                                    focused
                                    error={filenameError}
                                    helperText={filenameError ? "Nome do arquivo não pode conter caracteres especiais, ou espaço" : ""}
                                    onChange={changeFilename}
                                    label="Deseja renomear o arquivo?" />
                            </Grid>

                            <Grid item>
                                <Button
                                    onClick={handleUpload}
                                    variant="outlined">Calcular</Button>
                            </Grid>
                        </Grid>
                    </ModalDialogPage>
                </div>
            </>

        </LayoutPage>
    )

}


export default ConcessionsPage